html,
body {
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
  background: transparent;
}

html {
  scrollbar-width: none;
}

#root {
  height: 100%;
  width: 100%;
}

:where(*, *::before, *::after) {
  border-width: 0;
  border-style: solid;
  box-sizing: border-box;
  word-wrap: break-word;
}

ul {
  padding: 0;
  margin: 0;
}

a {
  text-decoration: none;
}

:where( blockquote, dl, dd, h1, h2, h3, h4, h5, h6, hr, figure, p, pre ) {
  margin: 0;
}
